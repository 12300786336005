import React, { useState } from "react";
import { Layout, Menu, Typography } from 'antd';
import { useSelector } from "react-redux";
import { permissionCheck } from '../global.functions'
import { NavLink } from "react-router-dom";
import Nav from './nav'

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Title } = Typography;

export default function LeftSidebar() {
    const [collapse, setCollapse] = useState(false)
    const {permissions} = useSelector((state)=>state)

    function menuGroup(item) {
        return <SubMenu key={item.key} icon={item.icon} title={item.name}>
            {item.items.map(value => {
                return permissionCheck(permissions, value.permissions)
                    ? <Menu.Item key={value.key} icon={value.icon}>
                        <NavLink to={value.to}>{value.name}</NavLink>
                    </Menu.Item>
                    : <div></div>
            })}
        </SubMenu>
    }

    function menuSingle(item) {
        return <Menu.Item key={item.key} icon={item.icon}>
            <NavLink to={item.to}>{item.name}</NavLink>
        </Menu.Item>
    }

    function logodisplay() {
        return collapse
        ? <div style={{margin:'6px',display:'flex',justifyContent:'center'}}>
            <img height='48' src='./images/Mahar Muszic (2000x2000).png' />
        </div>
        : <div style={{margin:'6px', padding:'6px',display:'flex',justifyContent:'center'}}>
        <img height='48' src='./images/mahar_tall_logo.png' />
    </div>
    }

    return (
        <Sider collapsible theme='dark'
            style={{ overflowY: 'auto' }}
            collapsed={collapse}
            width={250}
            onCollapse={() => setCollapse(!collapse)}>
            {logodisplay()}
            <Menu theme='dark' defaultSelectedKeys={['11']} mode="inline" style={{ marginBottom: '100px' }}>
                {Nav.map((value, index) => {
                    if (permissionCheck(permissions, value.permissions)) {
                        return value.items
                            ? menuGroup(value)
                            : menuSingle(value)
                    }
                    return <div key={`no_permission_${index}`}></div>
                })}
            </Menu>
        </Sider>
    )
}