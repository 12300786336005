import React from "react";
import { Form, Input, Button, Row, Col, Card } from 'antd';
import { login } from '../apis'
import { reportMessage } from '../global.functions'
import { useDispatch } from "react-redux";

export default function LoginForm() {

    const dispatch = useDispatch()

    async function onFinish(value) {
        login(value)
            .then(({ data }) => {
                if (data.access_token && data.user) {
                    dispatch({
                        type: 'set',
                        access_token: data.access_token,
                        userId: data.user.id,
                        currentUser: data.user,
                        permissions: data.user.accessLvl.permissions
                    })
                }
            })
            .catch(err => {
                console.log(err)
                reportMessage('username and password not match.', 'error')
            })
    }

    return (
        <Row justify="center" align="middle" style={{ height: '100vh', backgroundColor: '#ECECEC' }}>
            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                <Card title="Login Form" bordered={false} style={{ minWidth: '100%' }}>
                    <Form
                        name="login"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={onFinish}
                        autoComplete="false">
                        <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType='submit'>
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    )
}