import React from "react";
import LeftSidebar from '../LeftSidebar/index'
import TitleBar from '../titleBar/index'
import BodyRoutes from '../bodyRoutes/index'
import { Layout } from "antd";

export default function DefaultLayout(){

    return (
        <Layout style={{height:'100vh', backgroundColor:'#fafafa'}}>
            <LeftSidebar />
            <Layout className="site-layout" style={{height:'100vh', overflow:'hidden'}}>
                <TitleBar />
                <BodyRoutes />
            </Layout>
        </Layout>
    )
}