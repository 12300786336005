import React, { useState } from "react";
import { Button, Col, Drawer, Row, Modal, Form, Input } from "antd";
import { useSelector } from "react-redux";
import { userPasswordRewst } from "../apis";
import { reportMessage } from "../global.functions";

export default function UserProfile({ visible, setVisible, logOff }) {
  const [showModel, setShowModel] = useState(false);
  const { currentUser, access_token } = useSelector((state) => state);

  function onFinish(data) {
    console.log(data);
    setShowModel(false);
    userPasswordRewst("users/self_password_reset", access_token, data)
      .then((res) => {
        reportMessage("Username and Password change done", "success");
        logOff();
      })
      .catch((err) => {
        console.log(err);
        reportMessage(
          err.response ? err.response.data.error : "connection Error",
          "error"
        );
      });
  }

  return (
    <>
      <Drawer
        title="User Profile"
        closable={true}
        onClose={() => setVisible(!visible)}
        visible={visible}
        width={400}
        placement="right"
      >
        <Row gutter={[4, 24]} justify="end">
          <Col span={6}>Name</Col>
          <Col span={18}>: {currentUser.name}</Col>
          <Col span={6}>userName</Col>
          <Col span={18}>: {currentUser.userName}</Col>
          <Col span={6}>Age</Col>
          <Col span={18}>: {currentUser.age} Year</Col>
          <Col span={6}>Nrc</Col>
          <Col span={18}>: {currentUser.nrc}</Col>
          <Col span={6}>Phone</Col>
          <Col span={18}>: {currentUser.phone}</Col>
          <Col span={6}>Address</Col>
          <Col span={18}>: {currentUser.address}</Col>
          <Col span={6}>Access Lvl</Col>
          <Col span={18}>: {currentUser.accessLvl.name}</Col>
          <Col>
            <Button
              type="primary"
              onClick={() => {
                setShowModel(true);
              }}
            >
              Change Password
            </Button>
          </Col>
        </Row>
      </Drawer>
      <Modal
        visible={showModel}
        onCancel={() => setShowModel(!showModel)}
        footer={false}
        title="Change UserName and Password"
        width={500}
      >
        <Form
          name="login"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="false"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          <Row justify="end">
            <Col style={{ paddingRight: "26px" }}>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Change
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
