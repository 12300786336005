import React, { useState } from "react";
import { Row, Col, Layout, Avatar, Menu, Dropdown } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import UserProfile from './userProfile'

const { Header } = Layout;

export default function TitleBar() {

    const dispatch = useDispatch()
    const currentUser = useSelector((state) => state.currentUser)
    const [visible,setVisible] = useState(false)

    function logOffHandler() {
        dispatch({ type: 'set', access_token: false, user: {} })
    }

    const menu = (
        <Menu style={{ padding: '8px', marginTop: '4px' }}>
            <Menu.Item key={1} style={{ marginBottom: '8px' }} onClick={()=>setVisible(!visible)}>
                Profile
            </Menu.Item>
            <Menu.Item key={2} onClick={() => logOffHandler()}>
                Log Off
            </Menu.Item>
        </Menu>
    )
    return (
        <>
            <Header style={{backgroundColor:'#fff'}}>
                <Row style={{ width: '100%'}} justify='end'>
                    <Col>
                        <Dropdown overlay={menu} placement="bottomCenter">
                            <Avatar style={{ backgroundColor: '#722ed1', verticalAlign: 'middle' }} size="default" gap={1}>
                                {currentUser.name}
                            </Avatar>
                        </Dropdown>
                    </Col>
                </Row>
            </Header>
            <UserProfile visible={visible} setVisible={setVisible} logOff={logOffHandler} />
        </>
    )
}