import React from "react";
import {
  DashboardOutlined,
  UserOutlined,
  SnippetsOutlined,
  DeploymentUnitOutlined,
  UploadOutlined,
  StockOutlined,
  SettingOutlined,
  SoundOutlined,
  ProfileOutlined,
  PropertySafetyOutlined,
  NotificationOutlined,
  TeamOutlined,
  AuditOutlined,
  ApartmentOutlined,
  HeartOutlined,
  EyeOutlined,
  SolutionOutlined,
  ReconciliationOutlined,
  DollarCircleOutlined,
  DesktopOutlined,
} from "@ant-design/icons";

const Nav = [
  {
    name: "Analysis",
    to: "/dashboard",
    icon: <DashboardOutlined />,
    key: "sub1",
    permissions: "FREE",
    items: [
      {
        name: "General",
        to: "/dashboard_general",
        icon: <DashboardOutlined />,
        key: "11",
        permissions: "FREE",
      },
      {
        name: "Track",
        to: "/dashboard_track",
        icon: <SoundOutlined />,
        key: "12",
        permissions: "DASHBOARD_TRACK",
      },
      {
        name: "Album & Artist",
        to: "/dashboard_album",
        icon: <ProfileOutlined />,
        key: "13",
        permissions: "DASHBOARD_ALBUM",
      },
      // {
      //   name: 'Artist',
      //   to: '/dashboard_artist',
      //   icon: <UserOutlined />,
      //   key: '14',
      //   permissions: 'DASHBOARD_ARTIST'
      // },
      {
        name: "Subscription",
        to: "/dashboard_subscription",
        icon: <AuditOutlined />,
        key: "15",
        permissions: "DASHBOARD_SUBSCRIPTION",
      },
      {
        name: "Revenue",
        to: "/dashboard_revenue",
        icon: <StockOutlined />,
        key: "16",
        permissions: "DASHBOARD_REVENUE",
      },
      {
        name: "User",
        to: "/dashboard_user",
        icon: <TeamOutlined />,
        key: "17",
        permissions: "DASHBOARD_USER",
      },
      {
        name: "Podcast",
        to: "/dashboard_podcast",
        icon: <DeploymentUnitOutlined />,
        key: "18",
        permissions: "DASHBOARD_PODCAST",
      },
      {
        name: "User Detail",
        to: "/dashboard_user_detail",
        icon: <SolutionOutlined />,
        key: "19",
        permissions: "DASHBOARD_USER_DETAIL",
      },
      {
        name: "Track Detail",
        to: "/dashboard_track_detail",
        icon: <ReconciliationOutlined />,
        key: "20",
        permissions: "DASHBOARD_TRACK_DETAIL",
      },
    ],
  },
  {
    name: "DataView",
    to: "/DataView",
    icon: <HeartOutlined />,
    key: "sub2",
    permissions: "FREE",
    items: [
      {
        name: "Track ( Top 100 )",
        to: "/data_view_favourite_track",
        permissions: "TRACK_FAVOURITE",
        key: "21",
        icon: <SoundOutlined />,
      },
      {
        name: "Album ( Top 100 )",
        to: "/data_view_favourite_album",
        permissions: "RELEASE_FAVOURITE",
        key: "22",
        icon: <ProfileOutlined />,
      },
      {
        name: "Artist ( Top 100 )",
        to: "/data_view_favourite_artist",
        permissions: "ARTIST_FAVOURITE",
        key: "23",
        icon: <UserOutlined />,
      },
      // {
      //   name: 'Play List ( Top 100 )',
      //   to: '/data_view_revenue_track',
      //   permissions: 'TRACK_REVENUE',
      //   key: '24',
      //   icon: <ProfileOutlined />
      // }
    ],
  },
  {
    name: "Revenue",
    to: "/revenue",
    icon: <StockOutlined />,
    key: "sub4",
    permissions: "ACCESS_REVENUE",
    items: [
      {
        name: "Revenue Type",
        to: "/revenue_type",
        permissions: "REVENUE_WATCH",
        key: "41",
        icon: <PropertySafetyOutlined />,
      },
      {
        name: "Revenue List",
        to: "/revenue_list",
        permissions: "REVENUE_WATCH",
        key: "42",
        icon: <ProfileOutlined />,
      },
      {
        name: "Set Label Revenue",
        to: "/revenue_label",
        permissions: "REVENUE_WATCH",
        key: "43",
        icon: <DollarCircleOutlined />,
      },
      {
        name: "Upload Data Usage",
        to: "/upload_data_usage",
        permissions: "REVENUE_WATCH",
        key: "44",
        icon: <UploadOutlined />,
      },
      {
        name: "Revenue Income",
        to: "/revenue_income",
        permissions: "REVENUE_WATCH",
        key: "45",
        icon: <DollarCircleOutlined />,
      },
      {
        name: "Revenue Report",
        to: "/revenue_report",
        permissions: "REVENUE_REPORT",
        key: "46",
        icon: <EyeOutlined />,
      },
    ],
  },
  {
    name: "Notification",
    to: "/notification",
    icon: <NotificationOutlined />,
    key: "sub5",
    permissions: "NOTIFICATION_WATCH",
  },
  {
    name: "Mobile Ads",
    to: "/mobile_ads",
    icon: <DesktopOutlined />,
    key: "sub6",
    permissions: "MOBILE_ADS_WATCH",
  },
  {
    name: "Setting",
    to: "/setting",
    icon: <SettingOutlined />,
    key: "sub7",
    permissions: "ACCESS_SETTING",
    items: [
      {
        name: "User Management",
        to: "/setting_user_management",
        permissions: "USER_GETALL",
        key: "71",
        icon: <TeamOutlined />,
      },
      {
        name: "Access Lvl",
        to: "/setting_access_lvl_management",
        permissions: "ACCESSLVL_GETALL",
        key: "72",
        icon: <ApartmentOutlined />,
      },
    ],
  },
  {
    name: "EventReport",
    to: "/raw_event_report",
    icon: <SnippetsOutlined />,
    key: "sub8",
    permissions: "RAW_EVENT_REPORT",
  },
];

export default Nav;
