import { createStore } from 'redux'

const sessionState = sessionStorage.getItem('mahar_music')

const initialState = sessionState ? JSON.parse(sessionState) : {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      const newState = {...state,...rest}
      sessionStorage.setItem('mahar_music',JSON.stringify(newState))
      return { ...state, ...rest }
    default:
      sessionStorage.setItem('mahar_music',JSON.stringify(state))
      return state
  }
}

const store = createStore(changeState)
export default store
