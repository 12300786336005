import './App.css';
import { HashRouter} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Spin } from 'antd';
import React from 'react';
import Login from './login/index'
import DefaultLayout from './layout/index'

export default function App() {
  const { access_token } = useSelector(state => state)

  function loading() {
    return <Spin tip="Loading ..." size="large">
      <div style={{ width: '100vw', height: '100vh' }}></div>
    </Spin>
  }

  return <HashRouter>
    <React.Suspense fallback={() => loading()}>
      { !access_token
        ? <Login />
        : <DefaultLayout />}
    </React.Suspense>
  </HashRouter >

}
